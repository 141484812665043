const toggleZoomSlider = () => {
    const { body } = document;
    const zoomSliders = document.querySelectorAll('.js-zoom-slider')

    function openPopup(popup) {
        body.classList.add('is-overflow-hidden')
        popup.classList.add('is-fullscreen')
    }

    function closePopup(popup) {
        body.classList.remove('is-overflow-hidden')
        popup.classList.remove('is-fullscreen', 'is-full')
    }

    zoomSliders.forEach(slider => {
        const zoomBtn = slider.querySelector('.button-zoom')
        const closeBtn = slider.querySelector('.button-close')
        const sliderWrap = slider.querySelector('.swiper-wrapper')

        sliderWrap.addEventListener('click', () => {
            openPopup(slider)
        })

        closeBtn.addEventListener('click', () => {
            closePopup(slider)
        })

        zoomBtn.addEventListener('click', () => {
            if (!slider.classList.contains('is-full')) {
                slider.classList.add('is-full')
            } else {
                slider.classList.remove('is-full')
                zoomBtn.classList.remove('button-zoom--minus')
            }
        });
    })
}

export default toggleZoomSlider
