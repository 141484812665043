import Swiper, {EffectFade, Navigation, Pagination} from "swiper";

Swiper.use([Navigation, Pagination, EffectFade]);

const sliderArticle = () => {
    return new Swiper(".js-slider-article", {
        loop: true,
        speed: 500,
        direction: "horizontal",
        spaceBetween: 0,
        slidesPerView: 1,
        navigation: {
            nextEl: ".slider-article__nav--next",
            prevEl: ".slider-article__nav--prev",
        },
    });
};

export default sliderArticle;
