  // const endpoint = "https://aestech.k8s-stage.techiia.space/en/forms/user-request"; // endpoint for developing contact

const userRequest = () => {
  const form = document.querySelector(".js-form");

  if (form) {
    const { location } = document;
    const { origin } = location;
    const formType = form.closest('section').className
    const localization = location.pathname.substring(1, 3);
    const endpoint = `${origin}/${localization}/forms/${formType}`;
    const allInputs = [
      ...form.querySelectorAll(".js-input"),
    ];
    const checkbox = form.querySelector(".js-checkbox");
    const checkboxInput = checkbox.querySelector("input");

    const setInputErrorClass = (input) => input.classList.add("has-error");
    const removeInputErrorsClass = (item) => item.classList.remove("has-error");

    const setFormStatusLoading = () => form.classList.add("is-loading");
    const removeFormStatusLoading = () => form.classList.remove("is-loading");
    const setFormStatusLoaded = () => form.classList.add("is-loaded");
    const setFormStatusFailed = () => form.classList.add("is-failed");

    const resetAllInputs = () => {
      allInputs.forEach((input) => {
        removeInputErrorsClass(input);
      });
    };

    const resetAllErrors = () => {
      // eslint-disable-next-line no-unused-vars
      const allErrors = [
        ...document.querySelectorAll(".js-error"),
      ];

      allErrors.forEach((input) => input.remove());
    };

    const resetForm = () => {
      resetAllErrors();

      setTimeout(() => {
        form.reset();
        form.className = `${formType}__form js-form`;
      }, 5000);
    };

    const createErrorItem = (closestInput, content) => {
      closestInput.insertAdjacentHTML(
        "beforebegin",
        `
            <span class='textfield__error js-error'>
              ${content}
            </span>
        `
      );
    };

    checkbox.addEventListener("click", () => {
      if (checkbox.classList.contains("has-error")) {
        checkbox.classList.remove("has-error");
      }
    });

    form.addEventListener("submit", (e) => {
      e.preventDefault();
      const formData = new FormData(e.target)

      if (checkboxInput.checked) {
        resetAllInputs();
        resetAllErrors();
        setFormStatusLoading();

        if (formData.get('phone') === "") {
          formData.delete('phone')
        }

        fetch(endpoint, {
          method: "POST",
          body: formData,
        })
          .then((res) => {
            if (res.ok) {
              setFormStatusLoaded();
              resetForm();
            }

            return res.json();
          })
          .then((data) => {
            removeFormStatusLoading();

            // eslint-disable-next-line no-console
            console.log('data :', data)

            if (data.violations) {
              data.violations.forEach((key) => {
                switch (key.propertyPath) {
                  case "name":
                    setInputErrorClass(allInputs[0]);
                    createErrorItem(allInputs[0], key.title);
                    break;
                  case "phone":
                    setInputErrorClass(allInputs[1]);
                    createErrorItem(allInputs[1], key.title);
                    break;
                  case "email":
                    setInputErrorClass(allInputs[2]);
                    createErrorItem(allInputs[2], key.title);
                    break;
                  default:
                }
              });
            }
          })
          .catch(() => {
            setFormStatusFailed();
            resetForm();
          });
      } else {
        checkbox.classList.add("has-error");
      }
    });
  }
};

export default userRequest;
