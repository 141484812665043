import gsap, {TimelineMax} from 'gsap';
import isTouchDevice from '../helpers/detectTouch'

const menu = () => {
    const tl = new TimelineMax();
    const animation = gsap.timeline({paused: true})
    const {body} = document;
    const header = document.querySelector('.header');
    const hamburger = document.getElementById('hamburger');
    const mobileNav = document.getElementById('mobile-navigation');
    const navListItems = [...mobileNav.querySelectorAll('.nav__item')];
    const eventType = isTouchDevice() ? 'touchstart' : 'click';
    const scrollTriggerHeight = 10

    animation.to(mobileNav, {x: '0%', opacity: 1, duration: 0.7})

    const toggleMenu = () => {
        header.classList.toggle('is-menu-opened');
        hamburger.classList.toggle('is-active');
        mobileNav.classList.toggle('is-opened');
        hamburger.classList.toggle('is-disabled');
        body.classList.toggle('is-overflow-hidden');

        if (window.scrollY < scrollTriggerHeight && header.classList.contains('js-header')) {
            header.classList.toggle('is-transparent');
        }
    }

    const closeMenu = () => {
        toggleMenu()
        animation.reverse();
    };

    const openMenu = () => {
        toggleMenu()
        animation.play();

        tl
            .from(
                navListItems,
                {
                    x: -40,
                    opacity: 0,
                    stagger: 0.05,
                    delay: 0.5,
                });
    };

    hamburger.addEventListener(eventType, () => {
        const isMobileNavOpened = mobileNav.classList.contains('is-opened');

        if (isMobileNavOpened) {
            closeMenu();
        } else {
            openMenu();
        }
    });
};

export default menu;
