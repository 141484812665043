import Swiper, {Navigation, Pagination} from 'swiper';

Swiper.use([Navigation, Pagination]);

const sliderHistory = () => {
  return new Swiper(".js-slider-history", {
    loop: false,
    spaceBetween: 0,
    slidesPerView: 1,
    freeMode: false,
    watchSlidesProgress: true,
    navigation: {
      nextEl: '.slider-history__nav--next',
      prevEl: '.slider-history__nav--prev',
    },
    breakpoints: {
      1024: {
        spaceBetween: 125,
      },
      768: {
        spaceBetween: 144,
      },
      390: {
        spaceBetween: 0,
      }
    }
  })
}

export default sliderHistory;
