const toggleImgPopup = () => {
    const { body } = document;
    const imageList = document.querySelectorAll('.js-img-popup')

    if (imageList.length !== 0) {
        const popup = document.querySelector('.js-popup')
        const popupImage = popup.querySelector('.popup__img')
        const popupZoomBtn = popup.querySelector('.button-zoom')
        const popupCloseBtn = popup.querySelector('.button-close')
        let imageUrl = ''

        // eslint-disable-next-line no-inner-declarations
        function openPopup(width, height) {
            body.classList.add('is-overflow-hidden')
            popupImage.setAttribute('src', imageUrl)
            popup.classList.add('is-opened')

            if (width > height) {
                popupImage.classList.add('is-f-width')
            } else {
                popupImage.classList.add('is-f-height')
            }
        }

        // eslint-disable-next-line no-inner-declarations
        function closePopup() {
            body.classList.remove('is-overflow-hidden')
            popup.classList.remove('is-opened')
            popupImage.className = 'popup__img'
            popupZoomBtn.classList.remove('button-zoom--minus')
        }

        imageList.forEach(image => {
            image.addEventListener('click', () => {
                imageUrl = image.getAttribute('src')

                const { naturalWidth, naturalHeight } = image

                if (popup.classList.contains('is-opened')) {
                    closePopup()
                } else {
                    openPopup(naturalWidth, naturalHeight)
                }
            });
        })

        popup.addEventListener('click', (e) => {
            if (e.target === popup) {
                closePopup()
            }
        })

        popupCloseBtn.addEventListener('click', closePopup)

        popupZoomBtn.addEventListener('click', () => {

            if (!popupImage.classList.contains('is-full')) {
                popupImage.classList.add('is-full')
                popupZoomBtn.classList.add('button-zoom--minus')
            } else {
                popupImage.classList.remove('is-full')
                popupZoomBtn.classList.remove('button-zoom--minus')
            }
        });
    }
}

export default toggleImgPopup
