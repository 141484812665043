import Swiper, {Autoplay, EffectFade, Navigation, Pagination, Thumbs} from 'swiper';

Swiper.use([Navigation, Pagination, Autoplay, EffectFade, Thumbs]);

const sliderBenefits = () => {
  const swiper = new Swiper(".js-slider-benefits-2", {
    loop: false,
    spaceBetween: 0,
    slidesPerView: 1,
    freeMode: false,
    watchSlidesProgress: true,
    breakpoints: {
      1920: {
        spaceBetween: 104,
      },
      1024: {
        spaceBetween: 54,
      },
      768: {
        spaceBetween: 40,
      },
      390: {
        spaceBetween: 0,
      }
    }
  });

  const swiper2 = new Swiper(".js-slider-benefits", {
    loop: false,
    slidesPerView: 1,
    spaceBetween: 0,
    navigation: {
      nextEl: '.slider-benefits__nav--next',
      prevEl: '.slider-benefits__nav--prev',
    },
    thumbs: {
      swiper,
    },

  });

  return swiper2
}

export default sliderBenefits;
