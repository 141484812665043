const header = () => {
    const siteHeader = document.querySelector('.js-header');

    if (siteHeader) {
        const scrollTriggerHeight = 10
        let prevScrollPos = window.scrollY

        const setHeaderTransparentMode = () => siteHeader.classList.add("is-transparent")
        const setHeaderLightMode = () => siteHeader.classList.remove("is-transparent")

        const handleScroll = () => {
            const currentScrollPos = window.scrollY

            if (currentScrollPos > scrollTriggerHeight) {
                setHeaderLightMode()
            } else {
                setHeaderTransparentMode()
            }

            prevScrollPos = currentScrollPos
        };

        document.addEventListener("scroll", handleScroll)
    }
}

export default header
