const scrollToItem = (button, target) => {
  if (button) {
    button.addEventListener('click', () => {
      // eslint-disable-next-line no-unused-expressions
      target ?
        target.scrollIntoView({block: "end", behavior: "smooth"})
        :
        window.scrollTo({top: 0, behavior: 'smooth'});
    })
  }
}

export default scrollToItem