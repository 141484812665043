import menu from "./components/menu"
import sliderMedia from "./components/sliderMedia"
import userRequest from "./components/userRequest"
// import togglePreloader from "./components/togglePreloader"
import toggleOpening from "./components/toggleOpening"
import scrollToItem from "./components/scrollToItem"
import sliderBenefits from "./components/sliderBenefits"
import sliderHistory from "./components/sliderHistory"
import sliderArticle from "./components/sliderArticle"
import progress from "./components/progress"
import toggleImgPopup from "./components/toggleImgPopup";
import header from "./components/header";
import toggleZoomSlider from "./components/toggleZoomSlider";

header()
menu()
sliderMedia()
sliderArticle()
sliderBenefits()
sliderHistory()
userRequest()
// togglePreloader()
toggleOpening(document.querySelector(".js-toggle-lang"))
toggleOpening(document.querySelector(".js-toggle-contacts"))
scrollToItem(document.querySelector(".js-scroll-to-top"))
progress()
toggleZoomSlider()

// document.querySelectorAll('.js-img-popup').forEach(image => toggleImgPopup(image))


toggleImgPopup()
