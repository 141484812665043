import Swiper, { Navigation, Pagination } from 'swiper';

const sliderMedia = () => {
  return new Swiper('.js-slider-media', {
    modules: [Navigation, Pagination],
    loop: true,
    speed: 1000,
    direction: 'horizontal',
    spaceBetween: 0,
    navigation: {
      nextEl: '.slider-media__nav--next',
      prevEl: '.slider-media__nav--prev',
    },
  })
}

export default sliderMedia;
